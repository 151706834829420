import { Typography, Box } from "@mui/material";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Description2 from "../../../../../assets/images/operating-manual/4/description4.4a.png";
import Description2French from "../../../../../assets/images/operating-manual/4/description4.4a-french.png";
import Description3 from "../../../../../assets/images/operating-manual/4/description4.4b.png";
import Description3French from "../../../../../assets/images/operating-manual/4/description4.4b-french.png";
import CircularPlusIcon from "./CircularPlus";

const CreatePatient = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  const imgAttributes = [
    [Description2, Description2French],
    [Description3, Description3French],
  ];

  return (
    <Box
      id="create-select-patient"
      sx={{ ...sectionContainerStyle, padding: 3 }}
    >
      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(102deg, #5d5cfe -17.62%, #996ad2 30.87%, #f18e8e 87.31%)",
          color: "white",
          p: 1,
          pl: 4,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          4.4. {t("operating_manual_select_patient_title")}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pl: 4,
          pr: 4,
          gap: 2,
        }}
      >
        {/* Step 1 */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1" component="span">
            1. {t("operating_manual_select_patient_description1")}{" "}
            <u>{t("operating_manual_select_patient_description1_a")}</u>
          </Typography>
        </Box>
        <Box
          component="img"
          src={imgAttributes[0][t("operating_manual_image_index")]}
          alt="Description 2"
          sx={{ mt: 2, mb: 2, maxWidth: "50%" }}
        />

        <Typography variant="body1" component="span">
          2. {t("operating_manual_select_patient_description2")}
          <Box sx={{ display: "inline", p: 1 }}>
            <CircularPlusIcon />
          </Box>
          {t("operating_manual_select_patient_description2_a")}
        </Typography>

        <Typography variant="body1" component="p">
          {t("operating_manual_select_patient_description2_b")}
        </Typography>
        <Box
          component="img"
          src={imgAttributes[1][t("operating_manual_image_index")]}
          alt="Description 3"
          sx={{ mt: 2, mb: 2, maxWidth: "50%" }}
        />
      </Box>
      <Box
        sx={{
          p: 2,
          mt: 2,
          backgroundColor: "grey.100",
          color: "text.secondary",
          borderRadius: 1,
        }}
      >
        {t("operating_manual_select_patient_note")}
      </Box>
    </Box>
  );
};

export default CreatePatient;

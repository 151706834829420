import { Box, Typography } from "@mui/material";
import IntendedUse from "./IntendedUse";
import { Link } from "react-router-dom";
import Usage from "./Usage";
import Results from "./Results";
import { useContext } from "react";
import { OperatingManualStyleContext } from "../../../../contexts/OperatingManualStyleContext";
import { useTranslation } from "react-i18next";

const SystemDescription = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  return (
    <>
      <Box sx={{ ...sectionContainerStyle, padding: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background:
              "linear-gradient(102deg, #5d5cfe -17.62%, #996ad2 30.87%, #f18e8e 87.31%)",
            color: "white",
            p: 1,
            pl: 4,
            borderRadius: 2,
            mb: 2,
            boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
          }}
        >
          <Typography variant="h6" fontWeight={"bold"}>
            1. {t("operating_manual_system_description_title")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            pl: 4,
            pr: 4,
            gap: 2,
          }}
        >
          <Typography>
            {t("operating_manual_system_description_description1")}
          </Typography>
          <ol>
            <li>
              <span style={{ color: "#0066CC" }}>
                {t("operating_manual_system_description_description1_1_a")}
              </span>{" "}
              {t("operating_manual_system_description_description1_1_b")}
            </li>
            <li>
              <span style={{ color: "#0066CC" }}>
                {t("operating_manual_system_description_description1_2_a")}
              </span>{" "}
              {t("operating_manual_system_description_description1_2_b")}:{" "}
              <Link to="http://portal.retinalogik.ca/" target="_blank">
                portal.retinalogik.ca
              </Link>{" "}
            </li>
          </ol>
          <Typography fontWeight={"bold"}>
            {t("operating_manual_system_description_description1_2")}
          </Typography>
        </Box>
      </Box>
      <IntendedUse />
      <Usage />
      <Results />
    </>
  );
};

export default SystemDescription;

import SharedDeleteModal from "../SharedDeleteModal";

const DeleteProgressionAnalysisModal = (props) => (
  <SharedDeleteModal
    {...props}
    titleKey="exams_delete_confirmation"
    descriptionKey="progression_analysis_delete_description"
  />
);

export default DeleteProgressionAnalysisModal;

import { List, ListItem, ListItemText, Box } from "@mui/material";
import { Fragment, useCallback, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleClick = (main, subPath) => {
    const mainPath = `/operating-manual/${main}`;
    const fullPath = subPath ? `${mainPath}/${subPath}` : mainPath;

    if (location.pathname === mainPath) {
      if (!subPath) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        const element = document.getElementById(subPath);
        if (element) element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate(fullPath);
      if (!subPath) window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const isActiveLink = useCallback(
    (path) => location.pathname === path,
    [location.pathname]
  );

  useEffect(() => {
    const lastPart = location.pathname.split("/").pop();
    if (lastPart) {
      const element = document.getElementById(lastPart);
      if (element) element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const sections = [
    {
      title: t("operating_manual_important_information"),
      path: "important-information",
      children: [
        {
          title: t("operating_manual_warnings_and_precautions"),
          path: "warnings-and-precautions",
        },
      ],
    },
    {
      title: t("operating_manual_system_description"),
      path: "system-description",
      children: [
        {
          title: t("operating_manual_intended_use"),
          path: "intended-use",
        },
        { title: t("operating_manual_usage"), path: "usage" },
        { title: t("operating_manual_results"), path: "results" },
      ],
    },
    {
      title: t("operating_manual_software_updates"),
      path: "software-updates",
    },
    {
      title: t("operating_manual_getting_started"),
      path: "getting-started",
      children: [
        { title: t("operating_manual_features"), path: "features" },
        { title: t("operating_manual_test_patterns"), path: "test-patterns" },
        {
          title: t("operating_manual_head_mounted_device_controls"),
          path: "head-mounted-device-guide",
        },
        {
          title: t("operating_manual_portal_sections_overview"),
          path: "portal-guide",
        },
      ],
    },
    {
      title: t("operating_manual_instructions_for_use"),
      path: "instructions-for-use",
      children: [
        {
          title: t("operating_manual_account_registration"),
          path: "account-registration",
        },
        {
          title: t("operating_manual_configure_clinic_settings"),
          path: "configure-clinic-settings",
        },
        {
          title: t("operating_manual_turning_on_vr_device"),
          path: "turning-on-vr-device",
        },
        {
          title: t("operating_manual_create_select_patient"),
          path: "create-select-patient",
        },
        { title: t("operating_manual_creating_exam"), path: "creating-exam" },
        { title: t("operating_manual_launching_exam"), path: "launching-exam" },
        {
          title: t("operating_manual_viewing_results"),
          path: "viewing-results",
        },
      ],
    },
    {
      title: t("operating_manual_virtual_reality_device"),
      path: "virtual-reality-device",
      children: [
        { title: t("operating_manual_set_up_wifi"), path: "set-up-wifi" },
        { title: t("operating_manual_vr_device_care"), path: "vr-device-care" },
      ],
    },
    {
      title: t("operating_manual_faq"),
      path: "faq",
      children: [
        {
          title: t("operating_manual_faq_new_exam"),
          path: "#faq-new-exam",
        },
        {
          title: t("operating_manual_faq_controller_visibility"),
          path: "#faq-controller-visibility",
        },
        {
          title: t("operating_manual_faq_room_off_centre"),
          path: "#faq-room-off-centre",
        },
        {
          title: t("operating_manual_faq_vr_reset"),
          path: "#faq-vr-reset",
        },
        {
          title: t("operating_manual_faq_reset_password"),
          path: "#faq-reset-password",
        },
        {
          title: t("operating_manual_faq_edit_exam_settings"),
          path: "#faq-edit-exam-settings",
        },
        {
          title: t("operating_manual_faq_move_exam_patient"),
          path: "#faq-move-exam-patient",
        },
      ],
    },
    { title: t("operating_manual_browser"), path: "browser" },
    { title: t("operating_manual_security"), path: "security" },
    { title: t("operating_manual_contact_us"), path: "contact-us" },
    {
      title: t("operating_manual_appendix_a"),
      path: "appendix-a-symbol-glossary",
    },
    {
      title: t("operating_manual_appendix_b"),
      path: "appendix-b-patient-view",
      children: [
        {
          title: t("operating_manual_visual_field"),
          path: "appendix-visual-field",
        },
      ],
    },
    {
      title: t("operating_manual_appendix_c"),
      path: "appendix-c-portal-icon-glossary",
    },
  ];

  return (
    <List>
      {sections.map((section) => (
        <Fragment key={section.title}>
          <ListItem
            component={Link}
            to={`/operating-manual/${section.path}`}
            onClick={() => handleClick(section.path)}
            sx={{
              borderRadius: 1,
              transition: "color 0.3s ease, background-color 0.3s ease",
              color: "text.primary",
              backgroundColor: isActiveLink(`/operating-manual/${section.path}`)
                ? "#e8e8e8"
                : "none",
              "&:hover": { textDecoration: "none", backgroundColor: "#e8e8e8" },
            }}
          >
            <ListItemText
              primary={
                <Box fontWeight="fontWeightBold" fontSize="14px">
                  {section.title}
                </Box>
              }
            />
          </ListItem>
          {section.children?.map((child) => (
            <ListItem
              key={child.title}
              component={Link}
              to={`/operating-manual/${section.path}/${child.path}`}
              onClick={() => handleClick(section.path, child.path)}
              sx={{
                borderRadius: 1,
                transition: "color 0.3s ease, background-color 0.3s ease",
                color: "text.primary",
                backgroundColor: isActiveLink(
                  `/operating-manual/${section.path}/${child.path}`
                )
                  ? "#e8e8e8"
                  : "none",
                pl: 4,
                "&:hover": {
                  textDecoration: "none",
                  backgroundColor: "#e8e8e8",
                },
              }}
            >
              <ListItemText
                primary={
                  <Box fontWeight="fontWeightMedium" fontSize="14px">
                    {child.title}
                  </Box>
                }
              />
            </ListItem>
          ))}
        </Fragment>
      ))}
    </List>
  );
};

export default Sidebar;

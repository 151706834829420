import SharedDeleteModal from "../../../../components/UI/SharedDeleteModal";

const DeleteExamModal = (props) => (
  <SharedDeleteModal
    {...props}
    titleKey="exams_delete_confirmation"
    descriptionKey="exams_delete_description"
  />
);

export default DeleteExamModal;

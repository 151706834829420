// MUI Components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
// import { Divider } from "@mui/material";

// Utilities
import { getLocalDateAndTimeWithZone } from "../../../../utils/localeHelper";
import { calculateAge } from "../../../../utils/examReportsHelper";

const PatientInfoSection = ({ patient }) => {
  // internationalization
  const { t } = useTranslation();
  const sexTitles = {
    Male: t("gender_male"),
    Female: t("gender_female"),
    Undefined: t("word_na"),
  };
  return (
    <Grid item xs={12} spacing={2} gap={1} mt={2}>
      <Typography sx={{ fontWeight: "bold", fontSize: { xs: 12, sm: 14 } }}>
        {`${t("exams_patient_age")}: ${patient?.age ?? calculateAge(patient.dateOfBirth)} | ${t("patients_modal_sex")}: ${sexTitles[patient.sex]} | ${t("patients_edit_modal_birth")}: ${
          getLocalDateAndTimeWithZone(null, patient?.dateOfBirth)?.date ?? "N/A"
        }`}
      </Typography>
    </Grid>
  );
};

export default PatientInfoSection;

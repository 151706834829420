import { useParams } from "react-router-dom";
import PageIntro from "../../components/UI/PageIntro";
import { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getPatientById } from "../../services/Patient";
import useClinic from "../../hooks/useClinic";
import { getClinics } from "../../services/Clinic";
import DownloadButtons from "../../components/UI/DownloadButtons";
import { ValidatePDFContext } from "../../contexts/ValidatePDFContext";
import ProgressionAnalysisReportContainer from "../../containers/ProgressionAnalysisReport";
import { CommentModalContextProvider } from "../../contexts/CommentContext";
import Breadcrumb from "../../components/UI/ReportBreadcrumb";
import { Box } from "@mui/material";
import { PageContainerWrapper } from "../../styles/muiStylesHelper";

const ProgressionAnalysisReport = () => {
  const { patientId } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const { clinicId, clinicLicensing, setClinicLicensing } = useClinic();
  const [patient, setPatient] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [report, setReport] = useState(null);
  const forBackendPdf = false;

  const fetchAll = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();

      const [patient, clinicData] = await Promise.all([
        getPatientById(token, clinicId, patientId),
        getClinics(token, clinicId),
      ]);

      if (clinicData?.licensing) setClinicLicensing(clinicData.licensing);
      setPatient(patient);
      // Todo - Fetch progression analysis report
    } catch (e) {}
  }, [clinicId, getAccessTokenSilently, patientId, setClinicLicensing]);

  useEffect(() => {
    fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ValidatePDFContext.Provider value={{ forBackendPdf }}>
      <CommentModalContextProvider>
        <Box
          sx={() => PageContainerWrapper()}
          px={{ xs: 2, sm: 4, md: 8, lg: 12 }}
          mb={{ xs: 2 }}
        >
          <PageIntro
            pageTitle="Report"
            showBreadcrumb
            BreadcrumbComponent={
              <Breadcrumb
                isLoading={isLoading}
                patientId={patientId}
                patientName={`${patient?.firstName} ${patient?.lastName}`}
              />
            }
            showAddButton={false}
            showDeleteButton={false}
            showFilterInput={false}
            desktopMarginTop={3}
            desktopMarginBottom={3}
          />
          <DownloadButtons
            isLoading={isLoading}
            fileInfo={{}}
            reportInfoError={false}
            downloadPdf={null}
            shouldDisplayDicomButton={
              clinicLicensing?.canUseDicomReports === true
            }
            shouldDisplayPngButton={clinicLicensing?.canUsePngReports === true}
          />
          <ProgressionAnalysisReportContainer
            report={report}
            patient={patient}
          />
        </Box>
      </CommentModalContextProvider>
    </ValidatePDFContext.Provider>
  );
};

export default ProgressionAnalysisReport;

import SharedDeleteModal from "../../../../components/UI/SharedDeleteModal";

const DeleteClinicModal = (props) => (
  <SharedDeleteModal
    {...props}
    titleKey="clinics_delete_confirmation"
    descriptionKey="clinics_delete_description"
    customStyles={{ width: 400 }}
  />
);

export default DeleteClinicModal;

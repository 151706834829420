import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";

const Features = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);

  const featureCount = 14;
  return (
    <Box id="features" sx={{ ...sectionContainerStyle, padding: 3 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(102deg, #5d5cfe -17.62%, #996ad2 30.87%, #f18e8e 87.31%)",
          color: "white",
          p: 1,
          pl: 4,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Typography variant="h6" fontWeight={"bold"}>
          3.1 {t("operating_manual_features_title")}
        </Typography>
      </Box>

      <div
        className="my-5 bg-white p-6 rounded-lg shadow-lg max-w-4xl mx-auto"
        style={{ color: "#BD1A7D" }}
      >
        {[...Array(featureCount)].map((_, index) => (
          <div
            className="flex flex-col sm:flex-row items-start mb-6 last:mb-0 bg-white p-4 rounded-md shadow-sm"
            key={index}
          >
            <span className="py-2 px-4 text-pink-800 font-semibold rounded-md sm:min-w-[200px] w-full sm:w-auto text-center sm:text-left mr-0 sm:mr-4 mb-2 sm:mb-0">
              {t(`operating_manual_features_header${index + 1}`)}
            </span>
            <div className="flex-grow text-gray-700">
              {t(`operating_manual_features_content${index + 1}`)}
            </div>
          </div>
        ))}
      </div>
    </Box>
  );
};

export default Features;

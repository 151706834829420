import SharedDeleteModal from "../../../../components/UI/SharedDeleteModal";

const DeleteUserModal = (props) => (
  <SharedDeleteModal
    {...props}
    titleKey="user_delete_confirmation"
    descriptionKey="user_delete_description"
    customStyles={{ width: 400 }}
  />
);

export default DeleteUserModal;

import React from "react";
import { Grid } from "@mui/material";
import PrintButton from "../PrintButton";
import { AllFileTypes } from "../../../services/Exam";

const DownloadButtons = ({
  isLoading,
  fileInfo,
  reportInfoError,
  downloadPdf,
  shouldDisplayDicomButton,
  shouldDisplayPngButton,
}) => {
  const buttonConfigs = [
    {
      shouldDisplay: true,
      condition: fileInfo?.pdf?.hasBlobStorageError,
    },
    {
      shouldDisplay: shouldDisplayDicomButton,
      fileType: AllFileTypes.dicom,
      condition: fileInfo?.dicom?.hasBlobStorageError,
    },
    {
      shouldDisplay: shouldDisplayPngButton,
      fileType: AllFileTypes.png,
      condition: fileInfo?.png?.hasBlobStorageError,
    },
  ];

  return (
    <Grid container display="flex">
      {buttonConfigs
        .filter((config) => config.shouldDisplay)
        .map((config, index) => (
          <Grid key={index} item ml={index > 0 ? { xs: 0, sm: 1, md: 2 } : 0}>
            <PrintButton
              isLoading={isLoading || config.condition}
              handleDownloadPdf={downloadPdf}
              fileInfo={fileInfo}
              reportInfoError={reportInfoError}
              fileType={config.fileType}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export default DownloadButtons;

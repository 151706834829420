// Library methods
import { useEffect, useState, useCallback, useContext, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// MUI Components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

// Components
import VisualFieldCard from "../../containers/ClinicSettings/VisualFieldCard";
import SnackbarMessage from "../../components/UI/SnackbarMessage";
import Loader from "../../components/UI/Loader";

// Styles
import {
  CustomTextFieldOutlined,
  CustomTextFieldOutlinedInputProps,
  ModalCustomConfirm,
  PageContainerWrapper,
} from "../../styles/muiStylesHelper";

// Utilities
import useClinic from "../../hooks/useClinic";
import { allLanguageMap } from "../../i18n/languages";
import { getClinics, updateClinicSettings } from "../../services/Clinic";
import { EyeContext } from "../../contexts/ExamPropertyContext";
import { languageMap } from "../../i18n/languages";
import SearchBox from "../../components/UI/SearchBox";
import { getComparator } from "../../utils/tableHelper";
import { ToastContext } from "../../contexts/ToastContext";
import SharedTabs from "../../components/UI/SharedTabs";

const ClinicSettings = () => {
  // internationalization
  const { t } = useTranslation();

  // translation
  const { i18n } = useTranslation();

  // navigate
  const navigate = useNavigate();

  // auth0
  const { getAccessTokenSilently } = useAuth0();

  // clinic context
  const {
    clinicId,
    clinicName,
    setClinicLang,
    setPatientDefaultLang,
    setClinicSettings,
  } = useClinic();

  // eye context
  const { eyeLeft, eyeRight, eyeBoth } = useContext(EyeContext);

  // states
  const [clinicLanguage, setClinicLanguage] = useState({ name: "", val: "" });
  const [patientLanguage, setPatientLanguage] = useState({ name: "", val: "" });
  const [colorVisionDefaultTime, setColorVisionDefaultTime] =
    useState(undefined);
  const [vfExamDefaultValues, setVfExamDefaultValues] = useState(undefined);
  const [monocularReportPdfLayout, setMonocularReportPdfLayout] = useState({
    name: "",
    val: "",
  });
  const [isLoadingClinic, setIsLoadingClinic] = useState(true);

  const { toast, setToast } = useContext(ToastContext);

  const [value, setValue] = useState(0);

  // constant
  const enLangKey = "en";
  const frLangKey = "fr";
  const maxColorExamLimit = 20;

  const handleChange = (event, newValue) => setValue(newValue);

  const sortedLanguageList = useMemo(() => {
    const priorityKeys = [enLangKey, frLangKey];
    const priorityLanguages = [];
    const otherLanguages = [];

    Object.entries(allLanguageMap).forEach(([key, value]) => {
      const formattedValue = { ...value };
      if (priorityKeys.includes(key)) {
        formattedValue.name = t(value.name);
        priorityLanguages.push(formattedValue);
      } else {
        formattedValue.name = t(value.name);
        otherLanguages.push(formattedValue);
      }
    });
    const res = [
      ...priorityLanguages,
      ...otherLanguages.sort(getComparator("asc", ["name"])),
    ];
    return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, clinicId]);

  const availableClinicLanguages = useMemo(
    () => Object.keys(languageMap).map((key) => languageMap[key]),
    []
  );

  const shouldDisable = useMemo(() => {
    let isIncluded = 0;
    Object.keys(allLanguageMap).forEach((key) => {
      if (t(allLanguageMap[key]?.val) === clinicLanguage?.val) isIncluded++;
    });
    Object.keys(allLanguageMap).forEach((key) => {
      if (t(allLanguageMap[key]?.val) === patientLanguage?.val) isIncluded++;
    });
    return isIncluded !== 2;
  }, [t, clinicLanguage?.val, patientLanguage?.val]);

  // confirmation methods
  const handlePortalLanguageConfirmation = useCallback(
    (language) => {
      i18n.changeLanguage(language);
      setClinicLang(language);
      setPatientDefaultLang(patientLanguage);
    },
    [i18n, setClinicLang, setPatientDefaultLang, patientLanguage]
  );

  const handleLanguageUpdate = useCallback(async () => {
    const updateLanguage = {
      clinicDefaultLanguage: clinicLanguage?.val,
      patientDefaultLanguage: patientLanguage?.val,
    };

    // add to api
    try {
      // get token
      const token = await getAccessTokenSilently();
      await updateClinicSettings(token, clinicId, updateLanguage);

      const { settings } = await getClinics(token, clinicId);
      if (settings) setClinicSettings(settings);
      handlePortalLanguageConfirmation(
        Object.keys(languageMap).find(
          (key) => languageMap[key].val === clinicLanguage?.val
        )
      );
      setToast(() => ({
        success: true,
        message: t("settings_success"),
      }));
    } catch (error) {
      setToast(() => ({
        success: false,
        message: t("settings_error"),
      }));
    }
  }, [
    clinicLanguage?.val,
    patientLanguage?.val,
    getAccessTokenSilently,
    clinicId,
    setClinicSettings,
    handlePortalLanguageConfirmation,
    setToast,
    t,
  ]);

  const handleExamValuesUpdate = useCallback(async () => {
    const getEyeValue = (values) => {
      if (values.leftCheckBox && values.rightCheckBox) return eyeBoth;
      if (values.leftCheckBox) return eyeLeft;
      return eyeRight;
    };

    const updateLanguage = {
      colorVisionDefaultTimeLimit:
        colorVisionDefaultTime > maxColorExamLimit
          ? maxColorExamLimit * 60
          : colorVisionDefaultTime * 60,
      visualFieldDefaultAlgorithm: vfExamDefaultValues.examAlgorithm,
      visualFieldDefaultGridType: vfExamDefaultValues.visualGrid,
      visualFieldDefaultEye: getEyeValue(vfExamDefaultValues),
      visualFieldDefaultStimulusSizeType: vfExamDefaultValues.stimulusSizeType,
      visualFieldDefaultRequiresTutorial: vfExamDefaultValues.requiresTutorial,
      visualFieldDefaultAutoPause: vfExamDefaultValues.autoPause,
      visualFieldDefaultEncouragement: vfExamDefaultValues.encouragement,
      visualFieldDefaultCalibration: vfExamDefaultValues.calibration,
      visualFieldDefaultFixationPointShapeType:
        vfExamDefaultValues?.fixationType?.fixationPointShapeType,
      visualFieldDefaultFixationLossMethodType:
        vfExamDefaultValues?.fixationType?.fixationLossMethod,
      examDefaultRightEyeFirst: vfExamDefaultValues?.rightFirst,
    };

    // add to api
    try {
      // get token
      const token = await getAccessTokenSilently();
      await updateClinicSettings(token, clinicId, updateLanguage);

      const { settings } = await getClinics(token, clinicId);
      if (settings) setClinicSettings(settings);
      setToast(() => ({
        success: true,
        message: t("settings_success"),
      }));
    } catch (error) {
      setToast(() => ({
        success: false,
        message: t("settings_error"),
      }));
    }
  }, [
    colorVisionDefaultTime,
    vfExamDefaultValues,
    eyeBoth,
    eyeLeft,
    eyeRight,
    getAccessTokenSilently,
    clinicId,
    setClinicSettings,
    setToast,
    t,
  ]);

  const handleReportValuesUpdate = useCallback(async () => {
    const updateLanguage = {
      monocularReportPdfLayoutOption: monocularReportPdfLayout.val,
    };
    try {
      // get token
      const token = await getAccessTokenSilently();
      await updateClinicSettings(token, clinicId, updateLanguage);

      const { settings } = await getClinics(token, clinicId);
      if (settings) setClinicSettings(settings);
      setToast(() => ({
        success: true,
        message: t("settings_success"),
      }));
    } catch (error) {
      setToast(() => ({
        success: false,
        message: t("settings_error"),
      }));
    }
  }, [
    clinicId,
    getAccessTokenSilently,
    monocularReportPdfLayout.val,
    setClinicSettings,
    setToast,
    t,
  ]);

  // initial data fetching
  const fetchClinic = useCallback(async () => {
    if (!clinicName) navigate("/invalid");
    try {
      // get language key from value
      const getObjectKey = (obj, value) => {
        return Object.keys(obj).find((key) => obj[key].val === value);
      };

      // get authToken
      const token = await getAccessTokenSilently();

      const { settings } = await getClinics(token, clinicId);

      if (settings) {
        setVfExamDefaultValues((prev) => ({
          ...(prev ? prev : {}),
          examAlgorithm: settings.visualFieldDefaultAlgorithm,
          visualGrid: settings.visualFieldDefaultGridType,
          stimulusSizeType: settings.visualFieldDefaultStimulusSizeType,
          requiresTutorial: settings.visualFieldDefaultRequiresTutorial,
          leftCheckBox:
            settings.visualFieldDefaultEye === "Left" ||
            settings.visualFieldDefaultEye === "Both",
          rightCheckBox:
            settings.visualFieldDefaultEye === "Right" ||
            settings.visualFieldDefaultEye === "Both",
          autoPause: settings.visualFieldDefaultAutoPause,
          encouragement: settings.visualFieldDefaultEncouragement,
          calibration: settings?.visualFieldDefaultCalibration,
          fixationType: {
            fixationColorType: null,
            fixationPointShapeType:
              settings.visualFieldDefaultFixationPointShapeType,
            fixationLossMethod:
              settings.visualFieldDefaultFixationLossMethodType,
          },
          rightFirst: settings?.examDefaultRightEyeFirst,
        }));

        setClinicLanguage(
          languageMap[
            getObjectKey(languageMap, settings?.clinicDefaultLanguage)
          ]
        );
        setPatientLanguage(
          allLanguageMap[
            getObjectKey(allLanguageMap, settings.patientDefaultLanguage)
          ]
        );
        setMonocularReportPdfLayout({
          name: settings?.monocularReportPdfLayoutOption
            ? t(
                `settings_monocular_${settings?.monocularReportPdfLayoutOption?.charAt(0)?.toLowerCase() + settings?.monocularReportPdfLayoutOption?.slice(1)}`
              )
            : "",
          val: settings?.monocularReportPdfLayoutOption,
        });
        setColorVisionDefaultTime(settings.colorVisionDefaultTimeLimit / 60.0);
        setClinicSettings(settings);
        setIsLoadingClinic(false);
      }
    } catch (e) {
      console.log(e);
    }
  }, [
    clinicId,
    clinicName,
    getAccessTokenSilently,
    navigate,
    setClinicSettings,
    t,
  ]);

  const fetchClinicCall = useCallback(async () => {
    try {
      setIsLoadingClinic(true);
      await fetchClinic();
    } catch (e) {
      console.log(e);
    }
  }, [fetchClinic]);

  useEffect(() => {
    fetchClinicCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // component sections
  const getLanguageSettingsComponent = useMemo(() => {
    return (
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" flexDirection="column" gap={4} px={2}>
          <Box display="flex" flexDirection="column" gap={2.5} width={"250px"}>
            <Typography
              component="div"
              variant="h7"
              sx={{
                fontWeight: "bold",
                fontSize: { sm: "16px", lg: "20px" },
              }}
            >
              {t("settings_clinic_default_language")}
            </Typography>
            <SearchBox
              data={availableClinicLanguages}
              state={clinicLanguage}
              setState={setClinicLanguage}
              label={t("patients_modal_language")}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={2.5} width={"250px"}>
            <Typography
              component="div"
              variant="h7"
              sx={{
                fontWeight: "bold",
                fontSize: { sm: "16px", lg: "20px" },
              }}
            >
              {t("settings_patient_default_language")}
            </Typography>
            <SearchBox
              data={sortedLanguageList}
              state={patientLanguage}
              setState={setPatientLanguage}
              label={t("patients_modal_language")}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={0} px={2} pt={4}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: 3,
            }}
          >
            <Button
              variant="outlined"
              sx={ModalCustomConfirm}
              disabled={shouldDisable}
              onClick={handleLanguageUpdate}
            >
              {t("button_save_changes")}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }, [
    availableClinicLanguages,
    clinicLanguage,
    handleLanguageUpdate,
    patientLanguage,
    shouldDisable,
    sortedLanguageList,
    t,
  ]);

  const getExamCreationSettingsComponent = useMemo(() => {
    return (
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" flexDirection="column" gap={4} px={2}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography
              component="div"
              variant="h7"
              sx={{
                fontWeight: "bold",
                fontSize: { sm: "16px", lg: "20px" },
              }}
            >
              {t("settings_color_vision_time")}
            </Typography>
            <Box>
              <FormControl>
                <TextField
                  type="number"
                  id="color vision exam default time input"
                  value={colorVisionDefaultTime}
                  onChange={(e) => {
                    if (Number(e.target.value) > maxColorExamLimit) {
                      setColorVisionDefaultTime(maxColorExamLimit);
                    } else {
                      setColorVisionDefaultTime(e.target.value);
                    }
                  }}
                  label={t("settings_time_in_minutes")}
                  variant="outlined"
                  fullWidth
                  sx={() =>
                    CustomTextFieldOutlined({
                      mt: "0.75rem",
                      mr: "1.25rem",
                    })
                  }
                  InputProps={{
                    inputProps: { min: 0, max: maxColorExamLimit },
                    sx: {
                      ...CustomTextFieldOutlinedInputProps,
                      width: "156px",
                    },
                  }}
                  onKeyPress={(event) => {
                    // only let's the user type in a number
                    const isNotNumber =
                      event?.key === "-" ||
                      event?.key === "+" ||
                      event?.key === ".";

                    if (isNotNumber) event.preventDefault();
                  }}
                  color="warning"
                />
              </FormControl>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap={0}>
            <Typography
              component="div"
              variant="h7"
              sx={{
                fontWeight: "bold",
                fontSize: { sm: "16px", lg: "20px" },
              }}
            >
              {t("settings_visual_field_template")}
            </Typography>
            <Box>
              <FormControl>
                {[
                  { label: "Visual Field", value: "visual-field", type: "" },
                ]?.map((item) => (
                  <VisualFieldCard
                    key={item?.value}
                    examDefaultValue={vfExamDefaultValues}
                    setExamDefaultValue={setVfExamDefaultValues}
                  />
                ))}
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={0} px={2} pt={4}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: 3,
            }}
          >
            <Button
              variant="outlined"
              sx={ModalCustomConfirm}
              disabled={
                !(
                  colorVisionDefaultTime !== undefined &&
                  colorVisionDefaultTime !== ""
                )
              }
              onClick={handleExamValuesUpdate}
            >
              {t("button_save_changes")}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }, [colorVisionDefaultTime, handleExamValuesUpdate, t, vfExamDefaultValues]);

  const ReportCustomization = useMemo(
    () => (
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" flexDirection="column" gap={4} px={2}>
          <Box display="flex" flexDirection="column" gap={2.5}>
            <Typography
              component="div"
              variant="h7"
              sx={{
                fontWeight: "bold",
                fontSize: { sm: "16px", lg: "20px" },
              }}
            >
              {t("settings_monocular_padf_layout")}
            </Typography>
            <Box width={"250px"}>
              <SearchBox
                data={[
                  { name: t("settings_monocular_single"), val: "Single" },
                  { name: t("settings_monocular_multiple"), val: "Multiple" },
                ]}
                state={monocularReportPdfLayout}
                setState={setMonocularReportPdfLayout}
                label={t("settings_monocular_layout")}
              />
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={0} px={2} pt={4}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: 3,
            }}
          >
            <Button
              variant="outlined"
              sx={ModalCustomConfirm}
              disabled={shouldDisable}
              onClick={handleReportValuesUpdate}
            >
              {t("button_save_changes")}
            </Button>
          </Box>
        </Box>
      </Box>
    ),
    [handleReportValuesUpdate, monocularReportPdfLayout, shouldDisable, t]
  );

  const tabs = [
    {
      label: t("settings_language_preference"),
      content: !isLoadingClinic ? getLanguageSettingsComponent : <Loader />,
    },
    {
      label: t("settings_exam_creation_customization"),
      content: !isLoadingClinic ? getExamCreationSettingsComponent : <Loader />,
    },
    {
      label: t("settings_report_customization"),
      content: !isLoadingClinic ? ReportCustomization : <Loader />,
    },
  ];

  return (
    <>
      <Box
        sx={() => PageContainerWrapper()}
        px={{ xs: 2, sm: 4, md: 6, lg: 10 }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            flexDirection: {
              xs: "column",
              sm: "column",
              ls: "column",
              md: "row",
            },
            minHeight: {
              md: "900px",
            },
            height: {
              xs: "100%",
            },
          }}
        >
          <Grid item xs={12}>
            <Box
              sx={{ width: "100%" }}
              mt={{ xs: 4, sm: 5 }}
              mb={{ xs: 0, sm: 2 }}
            >
              <SharedTabs tabs={tabs} value={value} onChange={handleChange} />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* edit language success/failure toasts */}
      <SnackbarMessage
        open={Boolean(toast.message)}
        onClose={() => setToast({ success: false, message: null })}
        success={toast.success}
        autoHideDuration={8000}
        text={toast.message}
      />
    </>
  );
};

export default ClinicSettings;

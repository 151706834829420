import React, { useState, useRef } from "react";
import { TextField, IconButton, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import Auth0Icon from "../../../assets/images/auth0/auth0.svg";

const ActionableSearchBar = ({
  placeholder,
  onSearchChange = () => {},
  disableSearchButton = false,
  onClear = () => {},
  initialExpanded = false,
  selectedRows = [],
  showDeleteButton = false,
  disableDeleteButton = false,
  deleteButtonOnClick = () => {},
  showAuth0Button = false,
  auth0ButtonOnClick = () => {},
  showAddButton = false,
  addButtonOnClick = () => {},
  showFilterInput = true,
}) => {
  const [expandSearchInput, setExpandSearchInput] = useState(initialExpanded);
  const inputRef = useRef(null);

  const clearSearchFilter = () => {
    if (onClear) onClear();
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      {showFilterInput && (
        <>
          {expandSearchInput && (
            <TextField
              placeholder={placeholder}
              inputRef={inputRef}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                style: {
                  backgroundColor: "#EFEFEF",
                  color: "#202338",
                  height: 42,
                  border: "0.531248px solid #282C3C",
                  borderRadius: 5.31248,
                },
                startAdornment: <SearchIcon sx={{ mr: 1, ml: 1 }} />,
                endAdornment: (
                  <CancelIcon
                    onClick={() => {
                      clearSearchFilter();
                      setExpandSearchInput(false);
                    }}
                    cursor="pointer"
                    sx={{ mr: 1, ml: 1 }}
                    data-testid="reset-button"
                  />
                ),
              }}
              data-testid="search-box"
              onChange={(e) => onSearchChange?.(e)}
              sx={{ width: { sm: 200, md: 150, lg: 250 }, mb: 0 }}
            />
          )}

          {!expandSearchInput && !disableSearchButton && (
            <IconButton
              data-testid="search-icon"
              aria-label="search"
              size="medium"
              sx={{
                color: "#202338",
                width: 42,
                height: 42,
                backgroundColor: "rgba(0,0,0,0.3)",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#202338",
                },
              }}
              disabled={disableSearchButton}
              onClick={() => {
                if (expandSearchInput) clearSearchFilter();
                setExpandSearchInput((prev) => !prev);
              }}
            >
              <SearchIcon fontSize="inherit" />
            </IconButton>
          )}
        </>
      )}

      {selectedRows.length > 0 && showDeleteButton && (
        <Tooltip
          title="Delete selected items"
          disableHoverListener={!disableDeleteButton}
        >
          <span>
            <IconButton
              data-testid="delete-icon"
              aria-label="delete"
              size="medium"
              sx={{
                color: "#C11717",
                width: 42,
                height: 42,
                backgroundColor: "rgba(193, 23, 17, 0.4)",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#202338",
                },
              }}
              disabled={disableSearchButton || disableDeleteButton}
              onClick={deleteButtonOnClick}
            >
              <DeleteForeverIcon fontSize="inherit" />
            </IconButton>
          </span>
        </Tooltip>
      )}

      {Boolean(showAuth0Button) && (
        <IconButton
          aria-label="auth0-icon-button"
          size="medium"
          sx={{
            color: "#202338",
            width: 42,
            height: 42,
            backgroundColor: "rgba(0,0,0,0.3)",
            "&:hover": {
              color: "white",
              backgroundColor: "#202338",
            },
          }}
          onClick={auth0ButtonOnClick}
        >
          <img src={Auth0Icon} alt="auth0-icon" />
        </IconButton>
      )}

      {showAddButton && (
        <IconButton
          aria-label="add"
          size="medium"
          sx={{
            color: "#202338",
            width: 42,
            height: 42,
            backgroundColor: "rgba(0,0,0,0.3)",
            "&:hover": {
              color: "white",
              backgroundColor: "#202338",
              borderColor: "#202338",
            },
          }}
          onClick={addButtonOnClick}
        >
          <AddIcon fontSize="inherit" />
        </IconButton>
      )}
    </div>
  );
};
export default ActionableSearchBar;

import React from "react";
import { Box, Tabs, Tab } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, tabPadding, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`shared-tabpanel-${index}`}
      aria-labelledby={`shared-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: tabPadding }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `shared-tab-${index}`,
    "aria-controls": `shared-tabpanel-${index}`,
  };
}

const SharedTabs = ({
  tabs,
  value,
  onChange,
  tabStyles,
  tabIndicatorColor = "#E2772E90",
  tabPadding = 3,
}) => {
  return (
    <Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "#E2772E90",
        }}
      >
        <Tabs
          value={value}
          onChange={onChange}
          aria-label="shared tabs"
          TabIndicatorProps={{
            style: { background: tabIndicatorColor || "#E2772E90" },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              sx={{
                "&.Mui-selected": { color: tab.selectedColor || "#E2772E" },
                ...(tabStyles || {}),
              }}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel
          key={index}
          value={value}
          index={index}
          tabPadding={tabPadding}
        >
          {tab.content}
        </TabPanel>
      ))}
    </Box>
  );
};

export default SharedTabs;

import SharedDeleteModal from "../../../../components/UI/SharedDeleteModal";

const DeletePatientModal = (props) => (
  <SharedDeleteModal
    {...props}
    titleKey="patients_delete_confirmation"
    descriptionKey="patients_delete_description"
  />
);

export default DeletePatientModal;
